import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import "./bookValuation.scss"
import { useStaticQuery, graphql } from "gatsby"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import ReactMarkdown from "react-markdown"
import ValuationForm from "@Components/forms/valuation"
import FairDealForm from "@Components/forms/fair-deal"
import MortgageCalc from "@Components/calculators/mortgagecalc/index"
import GetURL from "@Components/common/site/get-url"
import Modal from "react-bootstrap/Modal"
import MortgageForm from "@Components/forms/mortgageform"
import ProbatValuationForm from "@Components/forms/probat-valuation"
import ClientSevicesForm from "@Components/forms/clientservice"

const BookValuation = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        mortgageCalculator(publicationState: LIVE) {
          Content
          Cta_Label
          Cta_Url {
            Alias
          }
          Tail_Title
          Title
          Mortgage_Calculator_Tail_Block {
            Text
            Title
            Icon {
              alternativeText
              url
            }
          }
        }
        bookAValuation(publicationState: LIVE) {
          Title
          Fair_Deal_Valuation
          Probate_Valuation
          Valuation_Tail_Block {
            Text
            Title
            Icon {
              alternativeText
              url
            }
          }
        }
      }
    }
  `)

  const submitHandler = event => {
    event.preventDefault()
  }
  const branchUrl = props.branchDetails?.branchDetails?.pageUrl
  const branchMobileNum = props.branchDetails?.branchDetails?.branchDetailNum
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="book-valuation"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
          style={{ backgroundImage: `url(${props?.Banner_Image?.url})` }}
        >
          <Container>
            <Row>
              <Col sm="12">
                <div
                  className={
                    props?.Banner_Type === "Mortgage_Calculator"
                      ? "content-wrapper mortgage"
                      : "content-wrapper"
                  }
                >
                  <div className="valuation-left">
                    <div className="form-wrap">
                      {props?.Banner_Title && (
                        <motion.h2 variants={contentItem}>
                          {props?.Banner_Title}
                        </motion.h2>
                      )}
                      {props?.Banner_Content && (
                        <motion.div variants={contentItem}>
                          <ReactMarkdown
                            source={props?.Banner_Content}
                            allowDangerousHtml
                          />
                        </motion.div>
                      )}
                      {props?.Banner_Type === "Book_a_Valuation" ? (
                        <ValuationForm
                          branchUrl={branchUrl}
                          branchMobileNum={branchMobileNum}
                        />
                      ) : props?.Banner_Type === "Fair_deal" &&
                        props?.Alias === "probate-valuation" ? (
                        <ProbatValuationForm />
                      ) : props?.Banner_Type === "Fair_deal" ? (
                        <FairDealForm />
                      ) : props?.Banner_Type === "Mortgage_Calculator" ? (
                        <MortgageCalc class="page-calculator" />
                      ) : props?.Banner_Type==="Client_Services"?(
                           <ClientSevicesForm/>
                      ): null}
                      {/* <Tabs defaultActiveKey="sell" className="form-tab">
												<Tab eventKey="sell" title="I’d like to Sell">
													<form onSubmit={submitHandler}>
														<div className="form-content">
															<div className="input-wrap">
																<label htmlFor="name">Name:</label>
																<input type="text" id="name" class="form-control" />
															</div>
															<div className="input-wrap">
																<label htmlFor="email">Email Address:</label>
																<input type="email" id="email" class="form-control" />
															</div>
															<div className="input-wrap">
																<label htmlFor="email">Email Address:</label>
																<input type="email" id="email" class="form-control" />
															</div>
															<div className="input-wrap d-none d-md-block">
																<label htmlFor="contact-number">Property Address:</label>
																<input type="text" id="contact-number" class="form-control" />
															</div>
															<div className="input-wrap d-md-none">
																<label htmlFor="enquiry-line">Line of Enquiry:</label>
																<select id="enquiry-line" class="form-control">
																	<option value="1">General Enquiry</option>
																	<option value="2">General Enquiry</option>
																	<option value="3">General Enquiry</option>
																</select>
															</div>
															<div className="input-wrap d-md-none">
																<label htmlFor="message">Your Query:</label>
																<textarea id="message" placeholder="Write your message here..." />
															</div>
															<button className="btn">submit enquiry</button>
															<p>By clicking Submit, you agree to our <Link href="#"> Terms & Conditions </Link> and <Link href="#">Privacy Policy. </Link></p>
														</div>
													</form>
												</Tab>
												<Tab eventKey="let" title="I’d like to Let">
													No Data
												</Tab>
											</Tabs> */}
                    </div>
                  </div>
                  {props?.Banner_Type === "Book_a_Valuation" ? (
                    <div className="valuation-right">
                      {data.glstrapi?.bookAValuation?.Title && (
                        <motion.div variants={contentItem}>
                          <ReactMarkdown
                            source={data.glstrapi?.bookAValuation?.Title}
                            allowDangerousHtml
                          />
                        </motion.div>
                      )}
                      <motion.ul
                        className="our-speciality"
                        variants={contentItem}
                      >
                        {data.glstrapi?.bookAValuation?.Valuation_Tail_Block?.map(
                          (item, i) => (
                            <li>
                              <div className="icon-wrap">
                                {/* <i className="icon-availability"></i> */}
                                <i
                                  style={{
                                    backgroundImage: `url(${item?.Icon.url})`,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "0 0",
                                  }}
                                ></i>
                              </div>
                              <div className="speciality-text book-a-valuation">
                                <h4>{item.Title}</h4>
                                <ReactMarkdown
                                  source={item?.Text}
                                  allowDangerousHtml
                                />
                              </div>
                            </li>
                          )
                        )}
                      </motion.ul>
                    </div>
                  ) : props?.Banner_Type === "Mortgage_Calculator" ? (
                    <div className="valuation-right mortgage">
                      {/* {data.glstrapi?.mortgageCalculator?.Title && (
                        <motion.div variants={contentItem}>
                          <ReactMarkdown
                            source={data.glstrapi?.mortgageCalculator?.Title}
                            allowDangerousHtml
                          />
                        </motion.div>
                      )} */}
                      {data.glstrapi?.mortgageCalculator?.Content && (
                        <motion.div variants={contentItem}>
                          <ReactMarkdown
                            source={data.glstrapi?.mortgageCalculator?.Content}
                            allowDangerousHtml
                          />
                        </motion.div>
                      )}
                      {/* <motion.div
                        className="d-none d-lg-flex"
                        variants={contentItem}
                      >
                        <a
                          href="javascript:;"
                          onClick={() => handleShow()}
                          className="btn"
                        >
                          Start Application
                        </a>
                        
                        <Modal
                          show={show}
                          centered={true}
                          onHide={handleClose}
                          backdrop="static"
                          keyboard={false}
                          dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                        >
                          <Modal.Header
                            closeButton
                            className="contact-close-btn"
                          >
                            <Modal.Title className="w-100">
                              Application Form
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body className="form">
                            <MortgageForm />
                          </Modal.Body>
                        </Modal>
                      </motion.div> */}
                      {data.glstrapi?.mortgageCalculator?.Tail_Title && (
                        <motion.h2 variants={contentItem}>
                          {data.glstrapi?.mortgageCalculator?.Tail_Title}
                        </motion.h2>
                      )}
                      <motion.ul
                        className="our-speciality"
                        variants={contentItem}
                      >
                        {data.glstrapi?.mortgageCalculator?.Mortgage_Calculator_Tail_Block?.map(
                          (item, i) => (
                            <li>
                              <div className="icon-wrap">
                                {/* <i className="icon-availability"></i> */}
                                <i
                                  style={{
                                    backgroundImage: `url(${item?.Icon.url})`,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "0 0",
                                  }}
                                ></i>
                              </div>
                              <div className="speciality-text">
                                <h4>{item.Title}</h4>
                                <ReactMarkdown
                                  source={item?.Text}
                                  allowDangerousHtml
                                />
                              </div>
                            </li>
                          )
                        )}
                      </motion.ul>
                    </div>
                  ) : props?.Banner_Type === "Fair_deal" &&
                    props?.Alias === "probate-valuation" ? (
                    <div className="valuation-right">
                      {data.glstrapi?.bookAValuation?.Probate_Valuation && (
                        <motion.div variants={contentItem}>
                          <ReactMarkdown
                            source={
                              data.glstrapi?.bookAValuation?.Probate_Valuation
                            }
                            allowDangerousHtml
                          />
                        </motion.div>
                      )}
                    </div>
                  ) : props?.Banner_Type === "Fair_deal" ? (
                    <div className="valuation-right">
                      {data.glstrapi?.bookAValuation?.Fair_Deal_Valuation && (
                        <motion.div variants={contentItem}>
                          <ReactMarkdown
                            source={
                              data.glstrapi?.bookAValuation?.Fair_Deal_Valuation
                            }
                            allowDangerousHtml
                          />
                        </motion.div>
                      )}
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Container>
        </motion.section>
      )}
    </InView>
  )
}

export default BookValuation
