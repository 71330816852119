/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

 import React, { useState, useEffect } from "react"
 import PropTypes from "prop-types"
//  import { filterNumber, numberFormat, pmt } from "./util"
 import { defaultValues } from "./config"
 import Select from 'react-select';
 import { Button, Form, Container, FormControl, Row, InputGroup, Col, Dropdown} from "react-bootstrap";
 import infoImg from "../../../images/Info.svg"
 import Modal from "react-bootstrap/Modal"

 import $ from "jquery";
 import MortgageForm from "@Components/forms/mortgageform"
//  import {calculateMonthlyPayment} from "./mortage" 
 import { calculateHouseHoldSalary,  mortageCountryScheme,  mortagePropertyType, percentage,
  calculateMonthlyPayment,  updateCalculationResults, numberFormat,filterNumber,pmt } 
  from '@starberry/react-calculator-stampduty'

 // User project specific const
 const durationOptions = {start : 10,end: 35}
 const interestOptions = {start : 1.9,end: 4.5}
 const options = mortageCountryScheme;
 
 // DO NOT DO ANY CHNAGES - START
 const MortgageCalc = props => {
   const {
     propertyPrice,
     depositAmount,
     loadDuration,
     interestRate,
     currency,
     defaultResult,
     pricePrefix,
   } = props
 
   const prefix = ""
   const [validated, setValidated] = useState(false)
   const [showerror, setShowerror] = useState(false)
   const [monthlyPayment, setMonthlyPayment] = useState(0);
   const [purchasePrice, setPurchasePrice] = useState(
     prefix + numberFormat(propertyPrice)
   )
   const [depositPercentage, setDepositPercentage]=useState(10)
   
   var [deposit, setDeposit] = useState(prefix + percentage(props.propertyPrice, depositPercentage))

   const [duration, setDuration] = useState(loadDuration)
   const [interest, setInterest] = useState(interestRate)
   const [schemeAmount, setSchemeAmount] = useState(0)
   const [rebate, setRebate] = useState(0)
   const [maxRebate, setMaxRebate] = useState(30000)
   const [loan, setLoan] = useState(0)
   const [result, setResult] = useState("")
   const [warningMsg, setWarningMsg] = useState("VALUE MUST BE AT LEAST €100,000")

   const [durationErrorMsg, setDurationErrorMsg] = useState("")
   const [interestErrorMsg, setInterestErrorMsg] = useState("")  
   
   const [durationError, setDurationError] = useState("")
   const [interestError, setInterestError] = useState("")
   
   const currency_symbol = "€";

 const [buyerType, setBuyerType] = useState("Yes");
 const [newbuild, setNewbuild] = useState("Yes");
 const [firstHome, setFirstHome] = useState("");
 const [councilType, setCouncilType] = useState("all");
 const [helpScheme, setHelpScheme] = useState(false);
 
 const [concilHomePrice, setConcilHomePrice] = useState(0);
 const [concilAptPrice, setConcilAptPrice] = useState(0);
 const [propertyType, setPropertyType] = useState('all');
 const [houseHoldSalary, setHouseHoldSalary] = useState(0);

 //Mpodal setcion
 const [modalBody, setModalBody] = useState('Body');
 const [modalTitle, setModalTitle] = useState('Title');

 const [show, setShow] = useState(false)
 const handleClose = () => setShow(false)
 const handleShow = (event,title,body) => {
  setModalTitle(title)
  setModalBody(body)
  setShow(true)
 }

 //Application from popub
 const [applyShow, setApplyShow] = useState(false)
 const handleCloseApply = () => setApplyShow(false)
 const handleShowApply = () => {
  setApplyShow(true)
 }


   const [buyerToggle, setBuyerToggle] = useState(true);
   const [buildToggle, setBuildToggle] = useState(true);
   const [schemeToggle, setSchemeToggle] = useState(true);
   const [helpSchemeToggle, setHelpSchemeToggle] = useState(true);
   const [councilToggle, setCouncilToggle] = useState(false);
   const [councilPropertyToggle, setCouncilPropertyToggle] = useState(false);
   const [priceToggle, setPriceToggle] = useState(true);
   const [rebateToggle, setRebateToggle] = useState(true);
   const [firstHomeSchemeToggle, setFirstHomeSchemeToggle] = useState(true);
   const [rateTermToggle, setRateTermToggle] = useState(true);
   const [rateToggle, setRateToggle] = useState(true);
   const [termToggle, setTermToggle] = useState(true);
   const [loanToggle, setLoanToggle] = useState(true);
   const [depositToggle, setDepositToggle] = useState(true);
   const [priceLimitToggle, setPriceLimitToggle] = useState(false);
 
 
   const handlePrice = event => {
     let val = filterNumber(event.target.value) 
     setPurchasePrice(prefix + numberFormat(val))  
     //Validate the field input 
     if(event.target.value !== "€" && event.target.value !== "€0" && event.target.value !== "0" && event.target.value !== ""){
      let inputsArray = { 
        purchasePrice : event.target.value,
        buyerType : buyerType , 
        newbuild : newbuild ,
        firstHome : firstHome ,
        helpScheme : helpScheme ,
        councilType : councilType,
        propertyType : propertyType,
        concilHomePrice : concilHomePrice ,
        concilAptPrice : concilAptPrice,
        interest : interest,
        duration : duration,
        maxRebate : maxRebate,
        currency_symbol : currency_symbol,
      }
      // updateCalculationResults(inputsArray)  
      setResults(inputsArray);  

     }
     else {
       setPurchasePrice('')
       setLoan()
       setMonthlyPayment(0)
     } // input validation
   } // handle price

   const setResults = (args) =>{

    let response=updateCalculationResults(args);

      setPriceLimitToggle(response.priceLimitToggle)
      setWarningMsg(response.warningMsg)

      setDeposit(filterNumber(response.deposit));
      setRebate(filterNumber(response.rebateAmt));
      setSchemeAmount(filterNumber(response.schemeAmount));
      setLoan(filterNumber(response.loan));

      setMonthlyPayment(filterNumber(Math.round(response.monthlyPayment)));
      // setHouseHoldSalary(filterNumber(response.houseHoldSalary));
      setHouseHoldSalary(calculateHouseHoldSalary(response.loan,args.buyerType));


  } 

   const handleDuration = event => {
    let canCalculate = true;
    setDurationError(false)
    if(durationOptions.start > event.target.value){
      canCalculate=false;
      setDurationError(true)
      setDurationErrorMsg(`Minimum loan years is ` + durationOptions.start)
    }

    if(durationOptions.end < event.target.value ){
      canCalculate=false;
      setDurationError(true)
      setDurationErrorMsg(`Maximum loan years is ` + durationOptions.end)
    }
    
    setDuration(event.target.value)

    if(canCalculate){   
      setMonthlyPayment(
        calculateMonthlyPayment(
          parseInt(filterNumber(purchasePrice)),
          parseFloat(interest),
          parseInt(filterNumber(deposit)),
          event.target.value
        )
      );
      }


    //  const input = document.getElementById("purchase_price");

    //  const event = new Event("input", { bubbles: true });
   
    //  input.dispatchEvent(event);
   
     
    //  $('.btn-calculate').click(); 
    //  setMonthlyPayment(
    //    calculateMonthlyPayment(
    //      parseInt(filterNumber(purchasePrice)),
    //      parseFloat(interest),
    //      parseInt(filterNumber(deposit)),
    //      event.target.value
    //    )
    //  );
   }
 
   const handleInterest = event => {

    let canCalculate = true;
    setInterestError(false)
    if(interestOptions.start > event.target.value){
      canCalculate=false;
      setInterestError(true)
      setInterestErrorMsg(`Minimum interest rate is ` + interestOptions.start)
    }

    if(interestOptions.end < event.target.value ){
      canCalculate=false;
      setInterestError(true)
      setInterestErrorMsg(`Maximum interest rate is ` + interestOptions.end)
    }
     
    setInterest(event.target.value)

     setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(event.target.value),
        parseInt(filterNumber(deposit)),
        duration
      )
    );
     
   }

   const calculateHouseHoldSalary = ( 
    loanAmount,buyerType
  ) => {
    const result = parseInt(buyerType == "Yes" ? loanAmount / 4 : loanAmount / 3.5 );  
    return result;
    
  };
 

   const getResult = (interest, duration, loan) => {  
     let result = -pmt(
       interest / 100 / 12,
       filterNumber(duration) * 12,
       filterNumber(loan),
       0,
       1
     )
     setResult(numberFormat(Math.round(result)))
   }

 
   useEffect(() => {
     $('.btn-calculate').click();    
     if (defaultResult) {
       if (
         parseInt(filterNumber(purchasePrice)) &&
         parseInt(filterNumber(deposit))
       ) {
         let loan =
           parseInt(filterNumber(purchasePrice)) -
           parseInt(filterNumber(deposit))
         setLoan(prefix + loan)
       }
       getResult(interest, duration, loan)
     
       setMonthlyPayment(
         calculateMonthlyPayment(
           parseInt(filterNumber(purchasePrice)),
           parseFloat(interest),
           parseInt(filterNumber(deposit)),
           duration
         )
       ); 
       setHouseHoldSalary(calculateHouseHoldSalary(loan,buyerType)) // salary set
       console.log('setHouseHoldSalary',houseHoldSalary);
     }
   }, [defaultResult, purchasePrice, deposit, loan, interest, duration, prefix])
 
   useEffect(() => {
    let loan = parseInt(filterNumber(purchasePrice)) - parseInt(filterNumber(deposit))
       if (
         parseInt(filterNumber(purchasePrice)) &&
         parseInt(filterNumber(deposit))
       ) {       
            setLoan(prefix + loan)
       }
       getResult(interest, duration, loan)
       setMonthlyPayment(
         calculateMonthlyPayment(
           parseInt(filterNumber(purchasePrice)),
           parseFloat(interest),
           parseInt(filterNumber(deposit)),
           duration
         )
       );

       setHouseHoldSalary(calculateHouseHoldSalary(loan,buyerType)) // salary set
    

   }, [])
 

   const handleChange = (event, field_name) => {    
    let tempBuyerType = buyerType
    let tempNewbuild = newbuild
    let tempFirstHome = firstHome
    let tempHelpScheme = helpScheme
    let tempCouncilType = councilType
    let tempProperyType = propertyType
    let tempCouncilHomePrice = concilHomePrice
    let tempConcilAptPrice = concilAptPrice

     if( field_name == "councilType"){       
      setCouncilType(event.value)
      tempCouncilType = event.value
      tempCouncilHomePrice = event.value == "all" ? 0 : parseInt(event.value.replace(/[^\d.-]/g, ''))
      setConcilHomePrice(event.value == "all" ? 0 : parseInt(event.value.replace(/[^\d.-]/g, '')))  
       // setConcilHomePrice(0);
       setConcilAptPrice(0);   
       if(event.data_apt != undefined ){
         setCouncilPropertyToggle(true)
         setConcilAptPrice(parseInt(event.data_apt.replace(/[^\d.-]/g, '')))
         tempConcilAptPrice = parseInt(event.data_apt.replace(/[^\d.-]/g, ''))         
       }
       else{             
         setCouncilPropertyToggle(false)
       }                     
     } else if(field_name == 'councilProperyType'){
         setPropertyType(event.value)  
         tempProperyType = event.value
         // firstHomeSchemeToggle(false)
     }
     else {   
         if(event.target.name === "buyerType"){
           setBuyerType(event.target.value);
           tempBuyerType = event.target.value
           if(event.target.value == "Yes"){

             setSchemeToggle(true);
             setHelpSchemeToggle(true);

           }else{
             tempHelpScheme=false
            setFirstHome('No');
             tempFirstHome = 'No'
             setSchemeToggle(false);
             setHelpSchemeToggle(false);    
             setCouncilToggle(false);
             setCouncilPropertyToggle(false);  

           }      
          }else if(event.target.name === "newbuild"){
            tempNewbuild = event.target.value
           setNewbuild(event.target.value);
           if(event.target.value == "Yes" && buyerType == "Yes" ){

             setSchemeToggle(true);
             setHelpSchemeToggle(true);

           }else if(event.target.value == "No" && buyerType == "Yes" ){

             setSchemeToggle(false);
             setHelpSchemeToggle(false);
             setCouncilToggle(false);
             setCouncilPropertyToggle(false);  
             tempHelpScheme=false
             setFirstHome('No');
             tempFirstHome = 'No'   

           }   
          }else if(event.target.name === "firstHome"){
           setFirstHome(event.target.value);
           tempFirstHome = event.target.value
           if(event.target.value == "Yes" && buyerType == "Yes" && newbuild == "Yes" ){

             setSchemeToggle(true);
             setHelpSchemeToggle(true);
             setCouncilToggle(true);
             setFirstHomeSchemeToggle(true);

           } else if (event.target.value == "No" && buyerType == "Yes" && newbuild == "Yes" ){
             
             // If first scheme is no set concil value 0
             setConcilHomePrice(0);
             setConcilAptPrice(0);  
             
             // Hide all concile fields
             setCouncilToggle(false);
            //  setFirstHomeSchemeToggle(false);
             setCouncilToggle(false);
             setCouncilPropertyToggle(false);
         }
       }
       else if( event.target.name == "help_to_buy"){         
         tempHelpScheme = event.target.checked;
         setHelpScheme(tempHelpScheme)

         if(event.target.checked){
          //  setRebateToggle(true);  
           setRebate(deposit)
           setDeposit(0);

         }else{    
          //  setRebateToggle(false);
           setDeposit(rebate)
           setRebate(0)      

         }
       }             
     }
   
    // Result update function trigger
    let argArray = { 
      purchasePrice : purchasePrice,
      buyerType : tempBuyerType , 
      newbuild : tempNewbuild ,
      firstHome : tempFirstHome ,
      helpScheme : tempHelpScheme ,
      councilType : tempCouncilType,
      propertyType : tempProperyType,
      concilHomePrice : tempCouncilHomePrice ,
      concilAptPrice : tempConcilAptPrice,
      interest : interest,
      duration : duration,
      maxRebate : maxRebate,
      currency_symbol : currency_symbol,
    }
    setResults(argArray)  
 }
   // DO NOT DO ANY CHNAGES - END
   var monthly_payment = Math.round(monthlyPayment);
  
   return (
     <section className={`${props.class} calculator-section`}>
     <Container>
     <Row>
 
   <Col lg={12} xl={12}>
   {/* <ScrollAnimation animateOnce={true} animateIn="fadeIn">  */}
 
     <div className="calculator-form form mortgate-form">
       {showerror && (
         <div className="alert-error">
           <p>Highlighted fields are required | invalid</p>          
         </div>
       )}


      <Form className="row">        
         { buyerToggle &&
           <Form.Group className="form-wrap row buyer_section col-6">
            
              <Form.Label>
                First time buyer 
                <img src={infoImg} class="pl-2" alt="i"  
                onClick={(e) => handleShow(e,
                  'First time buyer',
                  `As a non-First Time Buyer, you may still be eligible to use the First Home Scheme.
                   Please click here to check if you are eligible, or contact our Financial Service team for further advice.`
                  )}
                  
                  />          
              </Form.Label>
              <div className="d-flex">
                <div className="d-inline-block">
                  <Form.Check type="radio" label="Yes" value="Yes" checked={ buyerType == 'Yes' ? 'true': ''} name="buyerType" id="buyerTypeYes" onChange={handleChange} />
                </div>
                <div className="d-inline-block pl-3"> 
                  <Form.Check type="radio" label="No" value="No" checked={ buyerType == 'No' ? 'true': ''} name="buyerType" id="buyerTypeNo" onChange={handleChange} />
                </div>
              </div>
           </Form.Group>   
         }
         { buildToggle &&
            <Form.Group className="form-wrap row build_section col-6">
              <Form.Label>New build home</Form.Label>
              <div className="d-flex">
                <div className="d-inline-block">
                  <Form.Check type="radio" label="Yes" value="Yes" name="newbuild" id="newbuildYes"  checked={ newbuild == 'Yes' ? 'true': ''} onChange={handleChange} />
                </div>
                <div className="d-inline-block pl-3">
                  <Form.Check type="radio" label="No" value="No" name="newbuild" id="newbuildNo" checked={ newbuild == 'No' ? 'true': ''} onChange={handleChange} />
                </div>
              </div>
            </Form.Group> 
         }
         { schemeToggle &&
             <Form.Group className="form-wrap row scheme_section col-6" >
                <Form.Label>First Home Scheme  
                <img src={infoImg} class="pl-2" alt="i"  
                onClick={(e) => handleShow(e,
                  'First Home Scheme',
                  `The First Home Scheme is a government affordable housing scheme based on the equity share model. The scheme can be used to finance up to 30% of your purchase of a newly built property. There are property price ceilings set across Ireland, by local authority area.                
                   Home buyers will receive funds from a participating bank in return for the First Home Scheme taking a percentage ownership in the property purchased. This is known as an equity share. There is no charge for the first 5 years.                  
                  For more information on the Shared Equity Scheme, please visit here. If you are using the FHS, you must have senior mortgage approval with the Participating Mortgage Lenders at the maximum 3.5 x Loan to Income (LTI)
                  `
                  )}/>
                  </Form.Label>
                <div className="d-flex">
                  <div className="d-inline-block">
                    <Form.Check type="radio" label="Yes" value="Yes" name="firstHome" id="FirstHomeYes" onChange={handleChange} />
                  </div>
                  <div className="d-inline-block pl-3">
                    <Form.Check type="radio" label="No" value="No" name="firstHome" id="FirstHomeNo" onChange={handleChange} />
                  </div>
                </div>
             </Form.Group>
           }
           { helpSchemeToggle &&
             <Form.Group className="form-wrap row help_scheme_section col-6">
                     <Form.Label>Help to Buy Scheme</Form.Label>
                     <Form.Label>
                      <Form.Check type="checkbox" name="help_to_buy" label="Yes" onChange={handleChange} />
                     </Form.Label>
             </Form.Group> 
           }
           { councilToggle && 
             <Form.Group className="form-wrap row council_section col-6">
                <Form.Label>County Council 
                  <img src={infoImg} class="pl-2" alt="i"  
                onClick={(e) => handleShow(e,
                  'County Council',
                  `Each region across Ireland will have a Shared Equity Scheme price cap applied. By entering your local County Council we are able to help calculate your estimated results for your region. For more on the regional price caps, please visit here and click on the Question "What are the maximum property price ceilings under the FHS?".`
                  )}/>
                   </Form.Label>
                <Select options={options} name="councilType" defaultValue={options[0]} placeholder="" className="react-select  form-control pl-3" classNamePrefix="react-select" onChange={(e) => handleChange(e,'councilType')} />
             </Form.Group>  
           }
           { councilPropertyToggle && 
             <Form.Group className="form-wrap row council_section col-6">
                     <Form.Label>Property Type</Form.Label>
                     <Select options={mortagePropertyType} name="councilProperyType" defaultValue={mortagePropertyType[0]} placeholder="" className="react-select form-control pl-3" classNamePrefix="react-select " onChange={(e) => handleChange(e,'councilProperyType')} />
             </Form.Group>  
           }
           { priceToggle &&
             <Form.Group className="form-wrap row price_section purchase_price col-12">

                  <Col md={5}> <Form.Label className="float-start">Property Price</Form.Label> </Col>
                  { priceLimitToggle &&
                    <Col md={7}> <Form.Label className="warning float-end"> {warningMsg} </Form.Label> </Col>
                  }
                 <Col md={12} className="addonrow ml-2">
                    <span className="input-addon-left">{currency_symbol}</span>
                    <Form.Control
                      className="form-control"
                      required
                      type="text"
                      id="purchase_price"
                      name="purchase_price"
                      value={purchasePrice}
                      placeholder="Purchase Price"
                      onChange={handlePrice}
                      autocomplete="off"
                    />                
                 </Col>
             </Form.Group>
           }
 
           { depositToggle && 
            <Form.Group className="form-wrap row rebate_section col-6 amount">
              <Form.Label>Minimum Cash Deposit Required  
              <img src={infoImg} class="pl-2" alt="i"  
                onClick={(e) => handleShow(e,
                  'County Council',
                  `Each region across Ireland will have a Shared Equity Scheme price cap applied. By entering your local County Council we are able to help calculate your estimated results for your region. For more on the regional price caps, please visit here and click on the Question "What are the maximum property price ceilings under the FHS?".`
                  )}/>    </Form.Label>
              <Form.Label className="main-amount">{currency}{numberFormat(deposit)} </Form.Label>
             </Form.Group>
             }
           { rebateToggle && 
             <Form.Group className="form-wrap row rebate_section col-6 amount">
                <Form.Label>Max Help to buy scheme rebate  
                <img src={infoImg} class="pl-2" alt="i"  
                onClick={(e) => handleShow(e,
                  'Max Help to buy scheme rebate',
                  `If you are a first time buyer who has paid income tax or Dirt Tax in Ireland in the past 4 years you can apply to claim back up to €30,000 of the purchase price of a new home, with the Help to Buy Scheme How to apply and details of revenue eligibility requirements. This calculator will show the maximum rebate obtainable as an indication. If you use the Help to Buy Scheme the maximum First Home Scheme share available is reduced to 20% of the purchase price.`
                  )}/>    

                </Form.Label>
                <Form.Label className="main-amount">{currency}{numberFormat(rebate)} </Form.Label>
             </Form.Group>
           }
           { firstHomeSchemeToggle && 
             <Form.Group className="form-wrap row first_available_section col-6 amount">
                <Form.Label>Max First Home Scheme Available</Form.Label>
                <Form.Label className="main-amount">{currency}{numberFormat(schemeAmount)} </Form.Label>
             </Form.Group>
           }
           { loanToggle && 
            <Form.Group className="form-wrap row loan_section col-6 amount">              
              <Form.Label>Loan Amount</Form.Label>
              <Form.Label className="main-amount">{currency}{numberFormat(loan)} </Form.Label>
             </Form.Group>
           }        
             { rateToggle && 
                 <div className="col-6 rate_section">
                     <Form.Label>Interest Rate (%) </Form.Label>
                     <input className="form-control border"
                      type="number" 
                      id="interest-rate" 
                      // value="3.5"
                      // tabindex="4"
                      value={interest}
                      onChange={handleInterest}
                      step="0.1"
                      max="4.5"
                      min="1.9"
                      autocomplete={false}></input>

                    { interestError && 
                    <Form.Label className="warning float-end pt-2"> {interestErrorMsg}</Form.Label>
                    }     
                 </div>
               }
               { termToggle && 
                 <div className="col-6 term_section">
                     <Form.Label>Loan Term (years)  </Form.Label>
                     <Form.Control
                       className="form-control border"
                       required
                       type="number"
                       name="loan_term"
                       value={duration}
                      //  value={loadDuration}  
                       placeholder=""
                       onChange={handleDuration}
                       autocomplete="off"
                       min={10}
                       max={35}
                     />
                      { durationError && 
                      <Form.Label className="warning float-end pt-2"> {durationErrorMsg}</Form.Label>
                      }
                 </div>
               }
         </Form>
       {/* {!showerror && result && ( */}
         <div className="alert-success calc-success">
           <Row className="form-footer mt-3">
               <Col md={12}>
                  <Form.Label className="result-title" >Your Mortgage Results:</Form.Label>               
               </Col>
               <Col md={6} className="mt-2">
                  <Form.Label className="result-title">Cash Deposit</Form.Label>
                  <div className="amount">
                    <span className="main-amount">{currency}{numberFormat(deposit)} </span>
                   </div>
                </Col>
                <Col md={6} className="mt-2">
                  <Form.Label className="result-title">Help to buy Scheme 
                  <img src={infoImg} class="pl-2" alt="i"  
                       onClick={(e) => handleShow(e,
                    'Help To Buy Scheme',
                    `If you are a first time buyer who has paid income tax or Dirt Tax in Ireland in the past 4 years you can apply to claim back up to 10% of the purchase price of a new home, up to a maximum of €30,000, with the Help to Buy Scheme How to apply and details of revenue eligibility requirements. This calculator will show the maximum rebate obtainable as an illustration.
                     If you use the Help to Buy Scheme the maximum First Home Scheme share available is reduced to 20% of the purchase price.
                    `
                  )}/>       
                  </Form.Label>
                  <div className="amount">
                      <span className="main-amount">{currency}{numberFormat(rebate)}</span>
                      </div>
                </Col>
                <Col md={6} className="mt-3">
                  <Form.Label className="result-title">Total Deposit
                  <img src={infoImg} class="pl-2" alt="i"  
                       onClick={(e) => handleShow(e,
                    'Total deposit',
                    `10% of the purchase price that must be paid on signing of contracts (less any booking deposit already paid).`
                  )}/>   

                  </Form.Label>
                  <div className="amount">
                      <span className="main-amount">{currency} {numberFormat( parseInt(deposit) + parseInt(rebate))}</span>
                      </div>
                </Col>
                <Col md={6} className="mt-3">
                  <Form.Label className="result-title">Monthy Repayments in First Year  
                  <img src={infoImg} class="pl-2" alt="i"  
                       onClick={(e) => handleShow(e,
                    'MONTHLY REPAYMENTS IN THE 1ST YEAR',
                    `First Home Scheme Service Charges apply from the beginning of the sixth year of ownership of a property, if the equity share is still in place.`
                  )}/>                   
                  </Form.Label>
                  <div className="amount">
                      <span className="main-amount">{currency} {numberFormat(monthly_payment)}</span>
                      </div>
                </Col>
                <div className="result-info">
                  <Form.Label className="footer-info-lbl mt-4">
                    Above illustration based on a 5 year fixed rate of 4% (APRC 4.5%) <br></br>
                    With the 5-year fixed rate default rate, repayments in years 1-5 will remain the same. If an alternative rate is selected, repayments will vary.<br></br>
                    No first home scheme service charge is applicable in year 1-5. From year 6 onwards a first home scheme service charge will apply.
                  </Form.Label>
                </div>
                <div className="household-section">
                  <Form.Label className="result-title">Household Salary Required</Form.Label>
                    <div className="amount">
                        <span className="main-amount">{currency} {numberFormat(houseHoldSalary)}</span>
                    </div>
                </div>
                <div className="household-info mt-2">
                <Form.Label className="footer-info-lbl mt-3">
                  This calculator is for illustrative and guidance purposes only.<br></br>
                  The amount you can borrow will depend on your individual circumstances and is subject to lending criteria, 
                  terms and conditions.<br></br>               
                  </Form.Label>
                </div>
           </Row>
         </div>
       {/* )} */}
     </div>
     {/* <a className="btn btn-default" href="javascript:;" onClick={() => handleShowApply()}> APPLY NOW </a> */}

{/* Mortgage application form popub */}
     <Modal
        show={applyShow}
        centered={true}
        onHide={handleCloseApply}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup apply-form"
      >
        <Modal.Header
          closeButton
          className="contact-close-btn"
        >
          <Modal.Title className="w-100">
            Application Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="form">
          <MortgageForm />
        </Modal.Body>
      </Modal>

{/* Info label popub */}
     <Modal
        show={show}
        centered={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w modal-form modal-form-buy-sell-rent mortgage-popub"
      >
        <Modal.Header
          closeButton
          className="contact-close-btn"
        >
          <Modal.Title className="w-100 ">
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="form">
         {modalBody}
        </Modal.Body>
      </Modal>
   {/* </ScrollAnimation> */}
 
     </Col> 
     </Row>
     </Container>
     </section>
   )
  }

 MortgageCalc.defaultProps = {
   propertyPrice: defaultValues.PROPERTY_VALUE,
   depositAmount: defaultValues.DEPOSIT,
   loadDuration: defaultValues.DURATION,
   interestRate: defaultValues.INTEREST,
   currency: defaultValues.CURRENCY,
   defaultResult: defaultValues.DEFAULT_RESULT,
   pricePrefix: defaultValues.PRICE_PREFIX,
   // instantResult: defaultValues.INSTANT_RESULT,
   // durationSuffix: defaultValues.DURATION_SUFFIX,
   // yearSuffix: defaultValues.YEAR_SUFFIX,
   // yearSelect: defaultValues.YEAR_SELECT,
 }
 
 MortgageCalc.propTypes = {
   propertyPrice: PropTypes.any,
   depositAmount: PropTypes.any,
   loadDuration: PropTypes.any,
   interestRate: PropTypes.any,
   currency: PropTypes.string,
   defaultResult: PropTypes.any,
   pricePrefix: PropTypes.any,
   // instantResult: PropTypes.any,
   // durationSuffix: PropTypes.any,
   // yearSuffix: PropTypes.any,
   // yearSelect: PropTypes.any
 }
 
 export default MortgageCalc
 